import React, {useEffect, useState} from 'react';
import {
    useHistory,
    useParams
} from "react-router-dom";
import Layout from "../components/Shared/Layout";
import { ContentWrapper, PathHeader, ContentHeader } from "../components/PageContentWidgets";
import styled from "styled-components";
import {dataService} from "../services/getContent.service";
import {dateConvertedLib} from "../shared/libs";
import useNewsContent from "../hooks/useNewsContent";
import { Markup } from 'interweave';
import {Icons} from "../Icons/Icons";
import {useTranslation} from "react-i18next";

const DateWrapper = styled.div`
  width: 254px;
  height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #777E91;
  margin-top: 40px;
  margin-bottom: 64px;
`

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const NewsDetailsWrapper = styled.div`
  width: 639px;
  margin-right: 129px;
  @media (max-width: 768px) {
    margin-right: 0px;
    margin-bottom: 64px;
    width: 100%;
  }
`

const NewsNavigationWrapper = styled.div`
  width: 352px;
  height: fit-content;
`

const NewsNavigationHeader = styled.div`
  width: 352px;
  height: 40px;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #141416;
  margin-bottom: 40px;
`

const NewsNavigationItem = styled.div`
  width: 352px;
  border-bottom: 1px solid #E6E8EC;
  height: fit-content;
  margin-bottom: 24px;
`

const NewsNavigationItemDate = styled.div`
  width: 352px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #777E91;
  margin-bottom: 8px;
`

const NewsNavigationItemTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #03153D;
  padding-bottom: 16px;
  cursor: pointer;
`


const Image = styled.img`
  width: 639px;
  height: 399px;
  margin-bottom: 40px;
  border-radius: 16px;
  @media (max-width: 768px) {
   width: 100%;
  }
`

const Description = styled.div`
  width: 639px;
  word-wrap: break-word;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  color: #141416;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const NewsDetails = () => {
    let { newsIndex } = useParams();
    const history = useHistory();
    const [newsData, setNewsData] = useState(null);
    const [previewNews, setPreviewNews] = useState([]);
    const { newsContent, setNewsContent } = useNewsContent();
    const { t } = useTranslation();

    useEffect(() => {
        if (!newsContent) {
            dataService
                .getAllNews()
                .then((news) => {
                    setNewsContent(news.data);
                });
        }
        dataService
            .getNewsByTitle(newsIndex)
            .then(({ data }) => {
                setNewsData(data);
            });
    }, [newsIndex])

    useEffect(() => {
        let newPreviewNews = [];
        if (newsData && newsContent) {
            let currentNewsIndex = 0;
            for (let i=0;i<newsContent.length;i++) {
                if (newsContent[i].slug === newsData.slug) {
                    currentNewsIndex = i;
                    break;
                }
            }

            for (let i=currentNewsIndex - 2; i<=currentNewsIndex + 2; i++) {
                if (
                    i >= 0
                    && i < newsContent.length
                    && i !== currentNewsIndex
                ) {
                    newPreviewNews.push(newsContent[i]);
                    if (newPreviewNews.length === 3) {
                        break;
                    }
                }
            }
            setPreviewNews(newPreviewNews);
        }
    }, [newsData, newsContent])

    if (!newsData || !newsContent) {
        return null;
    }

    const date = new Date(newsData.publish_date);
    const monthName = dateConvertedLib.monthIndexToName(date.getMonth());
    const dayNumber = date.getDate();

    return(
        <Layout>
            <div>
                <PathHeader paths={[t('Main'), t('News'), newsData.title]}/>
                <ContentWrapper>
                    <ContentHeader title={newsData.title} backLink={'news'}/>
                    <DateWrapper>
                        {`Опубликовано: ${dayNumber} ${monthName}`}
                    </DateWrapper>
                    <NewsWrapper>
                        <NewsDetailsWrapper>
                            {newsData.image? <Image src={newsData.image}></Image>:Icons.fullNullImage}

                            <Description>
                                <Markup content={newsData.content} />
                                </Description>
                        </NewsDetailsWrapper>

                        {previewNews.length===0?null:
                            <NewsNavigationWrapper>
                                <NewsNavigationHeader>Читайте также</NewsNavigationHeader>
                                {
                                    previewNews.map((newsData) => {

                                        const date = new Date(newsData.publish_date);
                                        const monthName = dateConvertedLib.monthIndexToName(date.getMonth());
                                        const dayNumber = date.getDate();

                                        return (
                                            <NewsNavigationItem>
                                                <NewsNavigationItemDate>{`${dayNumber} ${monthName}`}</NewsNavigationItemDate>
                                                <NewsNavigationItemTitle onClick={()=>history.push(`/news/${newsData.slug}`)}>{newsData.title}</NewsNavigationItemTitle>
                                            </NewsNavigationItem>
                                        )
                                    })
                                }
                            </NewsNavigationWrapper>}

                    </NewsWrapper>
                </ContentWrapper>
            </div>
        </Layout>)

}
export default NewsDetails;
